<template lang="pug">
#contact.flex.flex-col.items-center.space-y-8.pt-16
  Label.aspire-subtitle(:text="$t('contact.subtitle')")
  h1.aspire-title.text-aspire-blue-2
    | {{ $t('contact.title') }}
  .w-full.px-2.flex.flex-col.relative(
    class="xs:px-5 sm:container sm:mx-auto sm:max-w-xl md:max-w-2xl md:pt-24 lg:max-w-3xl lg:pt-32"
  )
    .card-container(class="md:space-x-8")
      .card.space-y-2
        SvgIcon(icon="phone", :classes="['w-4', 'h-4', 'sm:w-8', 'sm:h-8']")
        span
          | {{ $t('contact.callUs') }}
        span(class="sm:text-2xl")
          | {{ $t('contact.phone') }}
      .card.space-y-2.my-6(class="md:my-0")
        SvgIcon(icon="email", :classes="['w-4', 'h-4', 'sm:w-8', 'sm:h-8']")
        span
          | {{ $t('contact.support') }}
        span(class="xs:max-w-230-px sm:max-w-none! sm:text-2xl")
          | {{ $t('contact.email') }}
    .flex.flex-col.justify-center.space-y-6.rounded-lg.shadow-xl.h-full.shadow-all.px-6.pt-16.pb-12.shadow-outline.bg-white(
      class="lg:px-20"
    )
      form.flex.flex-col.justify-center.mt-6(@submit.prevent="submit")
        .form-group
          .icon
            SvgIcon(icon="user", style="left: 10px", :classes="['w-4', 'h-4']")
            input(
              v-model.trim.lazy="$v.emailData.template_params.from_name.$model",
              type="text",
              :placeholder="$t('contact.form.name')",
              class="focus:outline-none focus:shadow-outline",
              :class="{ 'border-red-500': $v.emailData.template_params.from_name.$error }"
            )
          div(v-if="$v.emailData.template_params.from_name.$dirty")
            .error.mt-2(
              v-if="!$v.emailData.template_params.from_name.required"
            )
              | {{ $t('contact.form.errors.name.required') }}
        .form-group
          .icon
            SvgIcon(
              icon="email",
              style="left: 10px",
              :classes="['w-4', 'h-4']"
            )
            input.w-full.border.rounded-lg.h-16.pl-8.pr-4.text-gray-700.outline-none.text-sm(
              v-model.trim.lazy="$v.emailData.template_params.from_email.$model",
              type="text",
              :placeholder="$t('contact.form.email')",
              class="focus:outline-none focus:shadow-outline",
              :class="{ 'border-red-500': $v.emailData.template_params.from_email.$error }"
            )
          div(v-if="$v.emailData.template_params.from_email.$dirty")
            .error.mt-2(
              v-if="!$v.emailData.template_params.from_email.required"
            )
              | {{ $t('contact.form.errors.email.required') }}
        .form-group
          .icon
            SvgIcon(
              icon="edit",
              style="left: 10px; top: 18px",
              :classes="['w-4', 'h-4']"
            )
            textarea.w-full.border.rounded-lg.h-48.p-4.pl-8.text-gray-700.outline-none.text-sm.scrollbar(
              v-model.trim.lazy="$v.emailData.template_params.message.$model",
              type="text",
              :placeholder="$t('contact.form.message')",
              class="focus:outline-none focus:shadow-outline",
              :class="{ 'border-red-500': $v.emailData.template_params.message.$error }"
            )
          div(v-if="$v.emailData.template_params.message.$dirty")
            .error(v-if="!$v.emailData.template_params.message.required")
              | {{ $t('contact.form.errors.message.required') }}
        div
          button.w-full.bg-aspire-yellow-1.rounded-full.text-white.font-semibold.text-sm.py-3.px-5(
            class="md:w-40 lg:py-4 hover:bg-aspire-yellow-3 focus:outline-none focus:shadow-outline"
          )
            | {{ $t('contact.form.send') }}
</template>

<script>
// Components
import SvgIcon from "@/components/SvgIcon.vue";
import Label from "@/components/Label.vue";

// Utils
import { showToast } from "@/components/Toast/store";

// Mixins
import { validationMixin } from "vuelidate";

// Validations
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    SvgIcon,
    Label,
  },
  mixins: [validationMixin],
  data() {
    return {
      emailData: {
        service_id: process.env.VUE_APP_EMAIL_SERVICE_ID,
        template_id: process.env.VUE_APP_EMAIL_TEMPLATE_ID,
        user_id: process.env.VUE_APP_EMAIL_USER_ID,
        template_params: {
          from_name: "",
          from_email: "",
          message: "",
        },
      },
      submitStatus: null,
    };
  },
  validations: {
    emailData: {
      template_params: {
        from_name: {
          required,
        },
        from_email: {
          required,
        },
        message: {
          required,
        },
      },
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        // do your submit logic here
        this.submitStatus = "PENDING";
        this.sendEmail();
      }
    },
    async sendEmail() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");

      try {
        const response = await fetch(
          "https://api.emailjs.com/api/v1.0/email/send",
          {
            method: "POST",
            headers,
            body: JSON.stringify(this.emailData),
          }
        );

        if (!response.ok)
          showToast(
            "error",
            this.$t("toast.error"),
            this.$t("contact.toast.error")
          );
        else {
          this.reset();

          showToast(
            "success",
            this.$t("toast.success"),
            this.$t("contact.toast.success")
          );
        }
      } catch (error) {
        showToast(
          "error",
          this.$t("toast.error"),
          this.$t("contact.toast.error")
        );
      }
      this.reset();
    },
    reset() {
      this.emailData.template_params.from_email = "";
      this.emailData.template_params.from_name = "";
      this.emailData.template_params.message = "";

      this.$v.$reset();
    },
  },
};
</script>

<style scoped>
input {
  @apply w-full border rounded-lg h-16 pl-8 pr-4 text-gray-700 outline-none text-sm;
}

.contact-icon {
  @apply flex justify-center items-center rounded-full w-12 h-12 bg-aspire-blue-1;

  & > svg {
    color: white;
  }
}

.error {
  @apply pl-2 text-xs text-red-600;
}

.contact-container {
  display: grid;
  grid-template-columns: 3rem auto;
}

.card-container {
  @apply flex flex-col;
}

.card {
  @apply flex flex-col items-center rounded-lg bg-white shadow-all-sm p-6;

  & > svg {
    @apply text-aspire-blue-2;
  }

  & > :nth-child(2) {
    @apply block text-aspire-yellow-1 uppercase font-bold text-xs;
  }

  & > :nth-child(3) {
    @apply text-lg text-aspire-blue-2 font-medium break-all;
  }
}

.form-group {
  @apply mb-8;

  & > .icon {
    @apply relative flex items-center w-full;

    & > svg {
      @apply absolute text-gray-200;
    }
  }
}

@screen sm {
  .contact-icon {
    @apply w-20 h-20;
  }

  .contact-container {
    grid-template-columns: 5rem auto;
  }
}

@screen md {
  .card-container {
    @apply flex-row absolute top-0;

    left: 50%;
    transform: translateX(-50%);
  }

  .card {
    width: 265px;
  }
}

@screen lg {
  .card-container {
    @apply mt-6;
  }
}
</style>
