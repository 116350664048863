<template lang="pug">
#home
  Header
  section#ourWork-wrap.flex.flex-col.justify-center.items-center.space-y-8.pt-16
    Label.aspire-subtitle(:text="$t('ourWork.subtitle')")
    h1.aspire-title.text-aspire-blue-2
      | {{ $t('ourWork.title') }}
    .grid.gap-6.justify-center(
      class="sm:container sm:mx-auto sm:grid-cols-2 lg:grid-cols-3 lg:pt-10"
    )
      .flex.flex-col.items-center.space-y-8.px-5
        .contact-icon
          SvgIcon(icon="education", :classes="['w-12', 'h-12']")
        .flex.flex-col.justify-center
          h2.text-xl.text-aspire-blue-2.text-center(class="lg:text-2xl")
            | {{ $t('ourWork.academicGuidance.title') }}
          p.mt-6.text-center.text-gray-600.leading-loose(class="lg:text-base")
            | {{ $t('ourWork.academicGuidance.text') }}
      .flex.flex-col.items-center.space-y-8.px-5
        .contact-icon
          SvgIcon(icon="report", :classes="['w-12', 'h-12']")
        .flex.flex-col.justify-center
          h2.text-xl.text-aspire-blue-2.text-center(class="lg:text-2xl")
            | {{ $t('ourWork.resultsOriented.title') }}
          p.mt-6.text-center.text-gray-600.leading-loose(class="lg:text-base")
            | {{ $t('ourWork.resultsOriented.text') }}
      .flex.flex-col.items-center.space-y-8.px-5(
        class="sm:col-span-2 sm:px-40 md:px-48 lg:px-8 lg:col-span-1"
      )
        .contact-icon
          SvgIcon(icon="financial", :classes="['w-12', 'h-12']")
        .flex.flex-col.justify-center
          h2.text-xl.text-aspire-blue-2.text-center(class="lg:text-2xl")
            | {{ $t('ourWork.financialResponsible.title') }}
          p.mt-6.text-center.text-gray-600.leading-loose(class="lg:text-base")
            | {{ $t('ourWork.financialResponsible.text') }}
  section#college-admission-wrap.relative
    CollegeAdmission
  section#knowledgebase-wrap.relative
    Knowledgebase
  section#about-wrap.relative
    About
  section#testimonials-wrap.relative
    Testimonial
  section#contact-wrap
    Contact
</template>

<script>
// Components
import Header from "@/components/Header.vue";
import Label from "@/components/Label.vue";
import CollegeAdmission from "@/components/aspire/CollegeAdmission.vue";
import Knowledgebase from "@/components/aspire/Knowledgebase.vue";
import About from "@/components/aspire/About.vue";
import Testimonial from "@/components/aspire/Testimonials.vue";
import Contact from "@/components/aspire/Contact.vue";
import SvgIcon from "@/components/SvgIcon.vue";

export default {
  components: {
    Header,
    Label,
    CollegeAdmission,
    Knowledgebase,
    About,
    Testimonial,
    Contact,
    SvgIcon,
  },
};
</script>

<style scoped>
section {
  width: 100%;
}

.contact-icon {
  @apply flex justify-center items-center rounded-full w-24 h-24 bg-aspire-blue-1;

  & > svg {
    color: white;
  }
}
</style>
