<template lang="pug">
.collapse(v-click-outside="contract")
  .collapse-header.flex.justify-between.items-center.px-5.py-6.cursor-pointer.font-bold(
    :class="[expand ? `${headerActiveClasses} shadow-none rounded-t-lg` : `shadow-all rounded-lg ${headerClasses}`]",
    @click="toggleExpand()"
  )
    h3
      | {{ title }}
    SvgIcon(v-show="!expand", icon="down-arrow", :classes="['w-3', 'h-3']")
    SvgIcon(v-show="expand", icon="up-arrow", :classes="['w-3', 'h-3']")
  transition(name="slide", mode="in-out")
    .collapse-body.shadow-lg.rounded-b-lg.bg-white(
      v-if="expand",
      :class="bodyClasses"
    )
      slot
</template>

<script>
// Components
import SvgIcon from "@/components/SvgIcon.vue";

export default {
  components: {
    SvgIcon,
  },
  props: {
    title: {
      type: String,
      default: "title",
    },
    active: {
      type: Boolean,
      default: false,
    },
    headerClasses: {
      type: String,
      default: "",
    },
    headerActiveClasses: {
      type: String,
      default: "",
    },
    bodyClasses: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      expand: false,
    };
  },
  created() {
    if (this.active) this.toggleExpand();
  },
  methods: {
    toggleExpand() {
      this.expand = !this.expand;
    },
    contract() {
      this.expand = false;
    },
  },
};
</script>

<style scoped>
.collapse-body {
  transform-origin: top;
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  transform: scaleY(0);
}
</style>
