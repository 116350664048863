<template lang="pug">
#about.flex.flex-col.items-center.pt-16.space-y-8
  Label.aspire-subtitle.z-10(:text="$t('about.subtitle')")
  .grid.gap-4.px-2(
    class="xs:px-5 sm:container sm:mx-auto lg:gap-0 md:grid-cols-2 lg:pt-10"
  )
    div
      h3.text-center.text-3xl.text-aspire-blue-2.font-bold.mx-auto(
        class="sm:max-w-none! md:mx-0 md:text-left lg:text-5xl lg:max-w-xs!",
        style="max-width: 200px;"
      )
        | {{ $t('about.founder.name') }}
      h3.text-center.text-3xl.font-semibold.tracking-tight.text-aspire-yellow-1(
        class="md:text-left lg:text-4xl"
      )
        | {{ $t('about.founder.title') }}
      p.mt-3.text-gray-600.leading-loose.text-justify(
        class="md:mt-5 lg:text-lg lg:pr-6 xl:pr-12"
      )
        | {{ $t('about.founder.text') }}
      button.w-full.px-8.py-3.text-white.font-medium.bg-aspire-yellow-1.rounded-md.mt-4(
        v-scroll-to="{ el: '#contact-wrap', offset: -70 }",
        class="hover:bg-aspire-yellow-4 focus:outline-none focus:shadow-outline md:w-56 lg:py-4 lg:text-lg lg:px-10"
      )
        | {{ $t('about.founder.btn') }}
    div
      LazyImg.w-full.h-300-px.w-full.object-top.object-cover(
        class="md:h-400-px lg:h-550-px",
        :lazy-src="require('img/about-img.jpg')"
      )
  #universities.px-2.pb-16(class="xs:px-5 sm:container sm:mx-auto md:pt-4 lg:pt-8")
    p.text-xl.font-semibold.tracking-wide.text-aspire-yellow-1.uppercase(
      class="md:text-center"
    )
      | {{ $t('about.universities.subtitle') }}
    h3.mt-2.text-2xl.font-extrabold.tracking-tight.leading-tight.text-aspire-blue-2(
      class="sm:text-3xl md:text-center md:max-w-md md:mx-auto"
    )
      | {{ $t('about.universities.title') }}
    .grid.gap-8.mt-10(class="md:grid-cols-2 lg:pt-4")
      .uni-item
        .icon
          SvgIcon(icon="university", :classes="['w-6', 'h-6']")
        .text
          h4.text-lg.font-medium.text-gray-900(class="xl:text-xl")
            | {{ $t('about.universities.uni1.title') }}
          p.mt-2.text-gray-600.leading-6
            | {{ $t('about.universities.uni1.text') }}
      .uni-item
        .icon
          SvgIcon(icon="university", :classes="['w-6', 'h-6']")
        .text
          h4.text-lg.font-medium.text-gray-900.text-xl
            | {{ $t('about.universities.uni2.title') }}
          p.mt-2.text-gray-600.leading-6
            | {{ $t('about.universities.uni2.text') }}
      .uni-item
        .icon
          SvgIcon(icon="university", :classes="['w-6', 'h-6']")
        .text
          h4.text-lg.font-medium.text-gray-900.text-xl
            | {{ $t('about.universities.uni3.title') }}
          p.mt-2.text-gray-600.leading-6
            | {{ $t('about.universities.uni3.text') }}
      .uni-item
        .icon
          SvgIcon(icon="university", :classes="['w-6', 'h-6']")
        .text
          h4.text-lg.font-medium.text-gray-900.text-xl
            | {{ $t('about.universities.uni4.title') }}
          p.mt-2.text-gray-600.leading-6
            | {{ $t('about.universities.uni4.text') }}
</template>

<script>
// Components
import SvgIcon from "@/components/SvgIcon.vue";
import Label from "@/components/Label";
import LazyImg from "@/components/LazyImg.vue";

export default {
  components: {
    SvgIcon,
    Label,
    LazyImg,
  },
};
</script>

<style scoped>
.uni-item {
  @apply flex;

  & > .text {
    @apply ml-4;
  }
}

.icon {
  @apply flex flex-shrink-0 items-center justify-center w-12 h-12 text-white bg-aspire-yellow-1 rounded-md;
}
</style>
