<template lang="pug">
#testimonials.flex.flex-col.items-center.space-y-8.pt-16.bg-aspire-yellow-4
  Label.aspire-subtitle.z-10(:text="$t('testimonials.subtitle')")
  h1.aspire-title.text-aspire-blue.z-10
    | {{ $t('testimonials.title') }}
  .grid.gap-10.px-2.z-10.pb-16(
    class="xs:px-5 sm:container sm:mx-auto md:grid-cols-2 lg:grid-cols-3 lg:pt-4"
  )
    div
      .card
        .card-body
          .fix-width
            SvgIcon(icon="quote-left")
          p
            | {{ $t('testimonials.first.text') }}
        .card-footer
          SvgIcon.text-gray-700(icon="quote-right")
          h5.text-gray-700.mt-2
            | {{ $t('testimonials.first.name') }}
          h6.ml-24.mt-2.pl-4.text-aspire-blue-2.text-xs
            | {{ $t('testimonials.first.job') }}
    div
      .card
        .card-body
          .fix-width
            SvgIcon(icon="quote-left")
          p
            | {{ $t('testimonials.second.text') }}
        .card-footer
          SvgIcon.text-gray-700(icon="quote-right")
          h5.text-gray-700.mt-2
            | {{ $t('testimonials.second.name') }}
          h6.ml-24.mt-2.pl-4.text-aspire-blue-2.text-xs
            | {{ $t('testimonials.second.job') }}
    div(class="md:col-span-2 md:mx-auto lg:col-span-1")
      .card
        .card-body
          .fix-width
            SvgIcon(icon="quote-left")
          p
            | {{ $t('testimonials.third.text') }}
        .card-footer
          SvgIcon.text-gray-700(icon="quote-right")
          h5.text-gray-700.mt-2
            | {{ $t('testimonials.third.name') }}
          h6.ml-24.mt-2.pl-4.text-aspire-blue-2.text-xs
            | {{ $t('testimonials.third.job') }}
</template>

<script>
// Components
import SvgIcon from "@/components/SvgIcon.vue";
import Label from "@/components/Label.vue";
import LazyImg from "@/components/LazyImg.vue";

export default {
  components: {
    SvgIcon,
    Label,
    LazyImg,
  },
};
</script>

<style scoped>
.card {
  @apply flex flex-col justify-center relative rounded-lg shadow-all-sm bg-white px-4 py-6;
}

.card-footer {
  @apply flex flex-col justify-end items-end w-full;

  & > svg {
    @apply text-aspire-yellow-1;
  }
}

.card-body {
  @apply flex;

  & > .fix-width > svg {
    @apply text-aspire-yellow-1;
  }

  & > p {
    @apply ml-2 text-sm text-gray-700 leading-loose;
  }
}

@screen md {
  .card {
    width: 344px;
  }
}

@screen lg {
  .card {
    width: auto;
  }

  .card-body {
    & > p {
      @apply text-base;
    }
  }
}
</style>
