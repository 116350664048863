<template lang="pug">
ul
  li.flex.mt-4(v-for="(item, idx) in items", :key="`list-item-${idx}`")
    SvgIcon.flex-shrink-0(icon="check", :class="bulletClasses")
    p.ml-4(:class="itemClasses")
      | {{ item }}
</template>

<script>
//Components
import SvgIcon from "@/components/SvgIcon.vue";

export default {
  components: {
    SvgIcon,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemClasses: {
      type: String,
      default: "text-aspire-blue-2 leading-snug",
    },
    bulletClasses: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped></style>
