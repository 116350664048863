<template lang="pug">
#college-admission.flex.flex-col.items-center.space-y-8.py-16
  Label.aspire-subtitle.z-10(:text="$t('collegeAdmission.subtitle')")
  .w-full.px-2(class="xs:px-5 sm:container sm:mx-auto lg:grid md:grid-cols-2")
    div
      h1.aspire-title.text-aspire-blue-2.mx-auto.mb-8(class="lg:text-left")
        | {{ $t('collegeAdmission.title') }}
      LazyImg.mx-auto(
        class="w-2/3 max-w-xs! sm:max-w-sm!",
        background-color="",
        :lazy-src="require('img/illustrations/undraw_exams.svg')"
      )
    .mt-6
      Collapse#service-1(
        active,
        header-classes="transition duration-300 ease-in-out bg-white text-aspire-blue-2",
        header-active-classes="transition duration-300 ease-in-out bg-aspire-yellow-1 text-white",
        body-classes="pb-4 px-4",
        :title="$t('collegeAdmission.initialConsultation.title')"
      )
        List(
          item-classes="text-gray-600",
          bullet-classes="text-aspire-yellow-1",
          :items="text.initialConsultation"
        )
      Collapse#service-2.mt-4(
        header-classes="transition duration-300 ease-in-out bg-white text-aspire-blue-2",
        header-active-classes="transition duration-300 ease-in-out bg-aspire-yellow-1 text-white",
        body-classes="pb-4 px-4",
        :title="$t('collegeAdmission.collegeCounseling.title')"
      )
        List(
          item-classes="text-gray-600",
          bullet-classes="text-aspire-yellow-1",
          :items="text.collegeCounseling"
        )
      Collapse#service-3.mt-4(
        header-classes="transition duration-300 ease-in-out bg-white text-aspire-blue-2",
        header-active-classes="transition duration-300 ease-in-out bg-aspire-yellow-1 text-white",
        body-classes="pb-4 px-4",
        :title="$t('collegeAdmission.studySkills.title')"
      )
        List(
          item-classes="text-gray-600",
          bullet-classes="text-aspire-yellow-1",
          :items="text.studySkills"
        )
</template>

<script>
// Components
import LazyImg from "@/components/LazyImg.vue";
import Collapse from "@/components/Collapse.vue";
import Label from "@/components/Label.vue";
import List from "@/components/List.vue";

export default {
  components: {
    LazyImg,
    Collapse,
    Label,
    List,
  },
  computed: {
    text() {
      return {
        initialConsultation: [
          this.$t("collegeAdmission.initialConsultation.text-1"),
          this.$t("collegeAdmission.initialConsultation.text-2"),
          this.$t("collegeAdmission.initialConsultation.text-3"),
          this.$t("collegeAdmission.initialConsultation.text-4"),
          this.$t("collegeAdmission.initialConsultation.text-5"),
        ],
        collegeCounseling: [
          this.$t("collegeAdmission.collegeCounseling.text-1"),
          this.$t("collegeAdmission.collegeCounseling.text-2"),
          this.$t("collegeAdmission.collegeCounseling.text-4"),
          this.$t("collegeAdmission.collegeCounseling.text-5"),
          this.$t("collegeAdmission.collegeCounseling.text-6"),
          this.$t("collegeAdmission.collegeCounseling.text-7"),
        ],
        studySkills: [
          this.$t("collegeAdmission.studySkills.text-1"),
          this.$t("collegeAdmission.studySkills.text-2"),
          this.$t("collegeAdmission.studySkills.text-3"),
          this.$t("collegeAdmission.studySkills.text-4"),
          this.$t("collegeAdmission.studySkills.text-5"),
        ],
      };
    },
  },
};
</script>

<style scoped></style>
