<template lang="pug">
div
  header.flex.flex-col.justify-center.items-center.space-y-5.relative.px-5(
    class="sm:px-20 lg:pb-20 lg:px-40 lg:justify-end"
  )
    h1.text-3xl.text-white.break-words.font-bold(class="sm:text-center lg:text-5xl")
      | {{ $t('header.title') }}
    h5.text-xl.text-white.font-lato(class="sm:text-center lg:text-2xl")
      | {{ $t('header.subtitle') }}
    button.bg-aspire-yellow-1.rounded-full.px-8.py-4.text-white.font-bold.cursor-pointer.z-10(
      v-scroll-to="{ el: '#contact-wrap', offset: -70 }",
      class="hover:bg-aspire-yellow-3 focus:outline-none focus:shadow-outline"
    )
      | {{ $t('header.btn') }}
    svg.absolute.left-0.bottom-0.w-full.fill-current.text-white(
      xmlns="http://www.w3.org/2000/svg",
      viewBox="0 0 1920 148",
      style="margin-bottom: -0.5px;"
    )
      path(
        d="M0 0l.015 148.108 1920.006.012-.014-148.111C1281.733 197.37 640.69 193.999 0 0z"
      )
</template>

<script>
export default {};
</script>

<style scoped>
header {
  background-image: linear-gradient(
      90deg,
      rgba(45, 55, 72, 0.35057773109243695) 0%,
      rgba(163, 184, 198, 0.4374124649859944) 100%
    ),
    url("~img/header-bg.jpg");
  background-size: cover;
  height: 500px;
}

@screen sm {
  header {
    height: 660px;
  }
}

@screen lg {
  header {
    height: 800px;
  }
}
</style>
