<template lang="pug">
#knowledgebase.flex.flex-col.items-center.space-y-8.py-16.bg-aspire-blue-3
  Label.aspire-subtitle.z-10(:text="$t('knowledgebase.subtitle')")
  h1.aspire-title.z-10.text-aspire-blue-2
    | {{ $t('knowledgebase.title') }}
  .w-full.grid.gap-6.px-2.z-10(
    class="xs:px-5 sm:container sm:mx-auto md:grid-cols-3 lg:pt-5"
  )
    YoutubeEmbed(
      v-for="(video, idx) in videos",
      :key="`video-${idx}`",
      :id="video.id",
      :thumb-quality="video.thumbQuality"
    )
</template>

<script>
// Components
import Label from "@/components/Label.vue";
import YoutubeEmbed from "@/components/YoutubeEmbed.vue";

export default {
  components: {
    Label,
    YoutubeEmbed,
  },
  data() {
    return {
      origin: `${window.location.protocol}//www.youtube.com`,
      videos: [
        {
          id: "patbkNnnAQ4",
          thumbQuality: "maxres",
        },
        {
          id: "lZbe3k1wj7g",
          thumbQuality: "maxres",
        },
        {
          id: "MDoc0qoKSHA",
          thumbQuality: "maxres",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
