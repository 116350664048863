<template lang="pug">
#youtube
  .video-container.relative.cursor-pointer(v-if="!play", @click="play = true")
    LazyImg.w-full(:lazy-src="thumbUrl")
    .icon.rounded-xl.px-5.py-3.bg-red-600.text-white.opacity-75
      SvgIcon(icon="play", :classes="['w-5', 'h-5']", style="margin-left: 4px")
  iframe.w-full.h-full(
    v-else,
    :src="videoUrl",
    frameborder="0",
    allow="autoplay; encrypted-media",
    allowfullscreen
  )
</template>

<script>
// Components
import SvgIcon from "@/components/SvgIcon.vue";
import LazyImg from "@/components/LazyImg.vue";

export default {
  components: {
    SvgIcon,
    LazyImg,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    thumbQuality: {
      type: String,
      default: "sd",
    },
    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      play: false,
    };
  },
  computed: {
    thumbUrl() {
      return `https://i.ytimg.com/vi/${this.id}/${this.thumbQuality}default.jpg`;
    },
    videoUrl() {
      const embedUrlBase = "https://www.youtube.com/embed/";
      const autoplayParams = {
        ...this.params,
        autoplay: 1,
        origin: window.location.origin,
      };
      const query = Object.keys(autoplayParams)
        .reduce(
          (prev, curr) => [...prev, [curr, autoplayParams[curr]].join("=")],
          []
        )
        .join("&");

      return [`${embedUrlBase}/${this.id}`, query].join("?");
    },
  },
};
</script>

<style scoped>
.icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.video-container:hover > .icon {
  opacity: 1;
}
</style>
